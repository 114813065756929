import React from 'react';
import styled from 'styled-components';
import brickwall from '../assets/images/icons/icon-brickwall.png';

const BlogStyles = styled.div`
    text-align: center;
    img {
        width: 150px;
        margin: 72px auto;
        display: block;
    }
    p {
        font-size: 18px;
    }
`


export default function Blog() {
  return (
    <BlogStyles>
        <h2>Overengineering - A software development blog</h2>
        <img src={brickwall} alt=""/>
        <p>Coming soon</p>
        {/* <a href="http://devgames.io">While here, please take a look at a few of my experiments in retro games using React</a> */}
    </BlogStyles>
  )
}